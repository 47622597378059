<template>
  <RecordViewWrapper>
    <Main>
      <sdPageHeader :title="i18n.t('notifications.notificationsTitle')">
        <template v-slot:buttons>
          <div class="setting-form-actions">
            <sdButton size="default" type="primary" @click="readAllNotification">
              {{ i18n.t('notifications.readAll') }}
            </sdButton>
          </div>
        </template>
      </sdPageHeader>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div>
              <TableWrapper class="table-data-view table-responsive" v-if="notificationsMeta">
                <a-table :pagination="false" :dataSource="dataSource" :columns="columns">
                  <template #customTitleMessage>
                    <div style="display: flex; cursor: pointer" @click="sortTable('message')">
                      <span>
                        {{ i18n.t('notifications.message') }}
                      </span>
                      <span style="margin-left: 10px">
                        <font-awesome-icon
                          :icon="faSort"
                          size="1x"
                          style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                          v-if="linkValue != 'message'"
                        />
                        <div v-else>
                          <font-awesome-icon
                            :icon="faSortUp"
                            size="1x"
                            style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                            v-if="order == 'asc'"
                          />
                          <font-awesome-icon
                            :icon="faSortDown"
                            size="1x"
                            style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                            v-else
                          />
                        </div>
                      </span>
                    </div>
                  </template>
                  <template #customTitleCreated>
                    <div style="display: flex; cursor: pointer" @click="sortTable('created_at')">
                      <span>
                        {{ i18n.t('notifications.createdAt') }}
                      </span>
                      <span style="margin-left: 10px">
                        <font-awesome-icon
                          :icon="faSort"
                          size="1x"
                          style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                          v-if="linkValue != 'created_at'"
                        />
                        <div v-else>
                          <font-awesome-icon
                            :icon="faSortUp"
                            size="1x"
                            style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                            v-if="order == 'asc'"
                          />
                          <font-awesome-icon
                            :icon="faSortDown"
                            size="1x"
                            style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                            v-else
                          />
                        </div>
                      </span></div
                  ></template>
                  <template #customTitleRead>
                    <div style="display: flex; cursor: pointer" @click="sortTable('read_at')">
                      <span>
                        {{ i18n.t('notifications.read') }}
                      </span>
                      <span style="margin-left: 10px">
                        <font-awesome-icon
                          :icon="faSort"
                          size="1x"
                          style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                          v-if="linkValue != 'read_at'"
                        />
                        <div v-else>
                          <font-awesome-icon
                            :icon="faSortUp"
                            size="1x"
                            style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                            v-if="order == 'asc'"
                          />
                          <font-awesome-icon
                            :icon="faSortDown"
                            size="1x"
                            style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                            v-else
                          />
                        </div>
                      </span></div
                  ></template>
                </a-table>
                <div style="text-align: right; margin-bottom: 20px">
                  <a-pagination
                    :default-page-size="pageSize"
                    v-model:pageSize="pageSize"
                    :show-size-changer="true"
                    v-model:current="notificationsMeta.current"
                    :total="notificationsMeta.count"
                    @change="onChange"
                  >
                    <template #buildOptionText="props">
                      <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                    </template>
                  </a-pagination>
                </div>
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from './style';
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import router from '../../routes/protectedRoute';
import { useI18n } from 'vue-i18n';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const route = useRoute();
    const { state, dispatch } = useStore();
    const notifications = computed(() => state.notifications.notifications);
    const notificationsMeta = computed(() => state.notifications.pagination.pagination);
    const i18n = useI18n();
    const pageNum = computed(() => route.params.pageNum);
    const linkValue = ref('id');
    const order = ref('asc');
    let pageSize = ref(10);

    const getableData = computed(() => {
      return {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        sortableLink: `&sort_by=${linkValue.value}&order=${order.value}`,
      };
    });
    const readNotification = (readAt, id) => {
      if (!readAt) {
        dispatch('readNotification', { id });
        dispatch('getAllNotifications', getableData.value);
        dispatch('getAllTosterNotifications', pageSize);
      }
    };
    const sortTable = (value) => {
      if (linkValue.value == value) {
        order.value == 'asc' ? (order.value = 'desc') : (order.value = 'asc');
      }
      linkValue.value = value;
      getableData.value.sortableLink = `&sort_by=${value}&order=${order.value}`;
      dispatch('getAllNotifications', getableData.value);
    };
    const onChange = (page) => {
      router.push('/notifications/' + page);
      getableData.value.pageNum = page;
      dispatch('getAllNotifications', getableData.value);
    };
    onMounted(() => {
      dispatch('getAllNotifications', getableData.value);
    });
    const closeSession = (id) => {
      dispatch('axiosnotificationsCloseSession', id)
        .then(() => {
          dispatch('axiosnotificationsGetData', pageNum, pageSize);
        })
        .then(() => {
          if (notifications.value.length - 1 < 1) {
            dispatch('auth/logout');
          }
        });
    };
    const columns = computed(() => [
      {
        dataIndex: 'message',
        key: 'message',
        slots: { title: 'customTitleMessage' },
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { title: 'customTitleCreated' },
      },
      {
        dataIndex: 'read_at',
        key: 'read_at',
        slots: { title: 'customTitleRead' },
      },
      {
        title: '',
        width: 0,
      },
    ]);

    watch(pageSize, () => {
      dispatch('getAllNotifications', getableData.value);
    });

    const dataSource = computed(() =>
      notifications.value.length
        ? notifications.value.map((person, key) => {
            const { id, attributes } = person;
            const { message, created_at, read_at } = attributes;
            return {
              key: key + 1,
              id,
              message: <div onMouseenter={() => readNotification(read_at, id)}>{message}</div>,
              created_at: <div onMouseenter={() => readNotification(read_at, id)}>{i18n.d(created_at, 'long')}</div>,
              read_at: (
                <div onMouseenter={() => readNotification(read_at, id)}>
                  {read_at ? i18n.t('notifications.read') : i18n.t('notifications.unRead')}
                </div>
              ),
            };
          })
        : [],
    );

    const readAllNotification = () => {
      dispatch('readAllNotification').then(() => {
        dispatch('getAllNotifications', getableData);
      });
    };

    return {
      notifications,
      notificationsMeta,
      i18n,
      pageSize,
      pageNum,
      dataSource,
      columns,
      order,
      faSort,
      linkValue,
      faSortUp,
      faSortDown,
      readNotification,
      readAllNotification,
      onChange,
      closeSession,
      sortTable,
    };
  },
};

export default ViewPage;
</script>
<style scoped></style>
